<template>
  <b-card>
    <b-row
      align-h="between"
      align-v="center"
    >
      <b-col
        sm="4"
        class="text-left"
      >
        <h4 class="m-0 mt-1">
          {{ trainingName }}
        </h4>
      </b-col>
      <b-col
        sm="4"
        class="text-right"
      >
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0 mt-1"
          right
        >
          <template #button-content>
            <b-button
              variant="outline-primary"
              class="ml-2"
            >
              <feather-icon
                size="16"
                icon="DownloadIcon"
                class="mr-50"
              />
              <span class="text-nowrap">Export</span>
            </b-button>
          </template>

          <b-dropdown-item @click="onDownloadReport('csv')">
            <span class="align-middle ml-50">Export CSV</span>
          </b-dropdown-item>

          <b-dropdown-item @click="onDownloadReport('xlsx')">
            <span class="align-middle ml-50">Export XLSX</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="align-items-start mb-0 mt-0">
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: programId, participantId: props.row.id },
              }"
              class="table-primary-link"
            >
              {{ props.row.name }}
            </router-link>
          </div>
        </div>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import { BButton, VBToggle, BDropdown, BDropdownItem, BCard, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { localDateStringOrDateRangeStringToUtcRange, makeErrorToast } from "@/libs/utils";
import trainingsService from "@/services/trainingsService";
import { trainingStatus, trainingStatusDisplay } from '@models';
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { 
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT, 
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT
} from "@/libs/utils";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

export default {
  components: {
    BCard,
    VueGoodTable,
    TablePagination,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BButton
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: false,
            placeholder: "Search Name",
          },
          width: "16em"
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: false,
            placeholder: "Search Email",
          },
          width: "16em"
        },
        {
          label: "Training Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.values(trainingStatusDisplay),
          },
          width: "16em"
        },
        {
          label: "Training Completed",
          field: "completed_date",
          filterOptions: {
            enabled: true,
            placeholder: "Training Completed",
            filterValue: '',
          },
          width: "16em",
          type: 'date',
          thClass: 'vgt-left-align',
          tdClass: "vgt-left-align search-date-selector",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
        {
          label: "Time Spent",
          field: "time",
          filterOptions: {
            enabled: false
          },
          width: "16em"
        },
      ],
      columnFilters: [],
      sort:  [{ field: 'name', type: 'asc' }],
      rows: [],
      searchTerm: "",
      trainingName: ""
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    programId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    const elements = document.getElementsByName("vgt-completed_date");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["status", "completed_date"]) {
        if (params.columnFilters[col]) {
          if (col == "status") {
            const status = Object.keys(trainingStatusDisplay).find(key => trainingStatusDisplay[key] === params.columnFilters[col]);
            if (status) {
              columnFilters.push({
                field: "status_id",
                value: status,
              });
            }
          } else if (col == "completed_date") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        // Get training detail
        const training = await trainingsService.getProgramTraining(
          this.$route.params.id,
          this.$route.params.trainingId,
        );
        if (training && training.data) {
          this.trainingName = training.data.course_name;
        }

        const response = await trainingsService.getTrainingUserList(
          this.$route.params.id,
          this.$route.params.trainingId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => {
          const trainingDetail = item.program_training_users && item.program_training_users.length
            ? item.program_training_users[item.program_training_users.length - 1]
            : null;

          return {
            id: item.id,
            name: item.full_name,
            email: item.email,
            status: trainingDetail ? trainingDetail.status.name : trainingStatusDisplay[trainingStatus.NOT_STARTED],
            time: this.getSpent(trainingDetail),
            completed_date: trainingDetail? trainingDetail.completed_date: null
          };
        });
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training log not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    getSpent(trainingDetail) {
      if (!trainingDetail) {
        return '0 minute';
      }

      const spentTime = trainingDetail.total_seconds_tracked || 0;

      if (spentTime === 0) {
        return '0 minute';
      }

      // Don't show if seconds less than 60
      if (spentTime < 60) {
        return '0 minute';
      }

      return `${Math.round(spentTime / 60)} minutes`;
    },
    onDownloadReport(type) {
      const programId = this.$route.params.id;
      const trainingId = this.$route.params.trainingId;

      const dowloadLink = `${BE_API_URL}/programs/${programId}/trainings/${trainingId}/report/download/${type}`;
      window.document.location = dowloadLink;
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
